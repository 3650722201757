<template>
    <div class="container-xl d-flex flex-column justify-content-center">
      <div class="col-12">
                  <div class="card card-md">
                    <div class="card-stamp card-stamp-lg">
                      <div class="card-stamp-icon bg-primary">
                        <!-- Download SVG icon from http://tabler-icons.io/i/ghost -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-certificate" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                            <path d="M5 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5"></path>
                            <circle cx="6" cy="14" r="3"></circle>
                            <path d="M4.5 17l-1.5 5l3 -1.5l3 1.5l-1.5 -5"></path>
                        </svg>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row align-items-center">
                        <div class="col-10">
                          <h3 class="h1">Violation of the SCUM EULA</h3>
                          <h4>What is <a style="color:dodgerblue" href="https://en.wikipedia.org/wiki/End-user_license_agreement" target="_blank">EULA</a>? - «end-user license agreement», shortened to EULA</h4>
                          <h4>By clicking "accept" and/or by installing and using the SCUM software, you acknowledge the terms of this agreement <a style="color:dodgerblue" href="https://store.steampowered.com/eula/513710_eula_0" target="_blank">SCUM EULA</a>. By agreeing to this license, you acknowledge that you understand the terms of this agreement and that you are responsible for any terms that may apply.</h4>
                          <hr/>
                          <h4>Information on RMT:</h4>
                          <div class="accordion" id="accordion-example">
                            <div class="accordion-item">
                    <h2 class="accordion-header" id="heading-1">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false">
                        What is RMT
                      </button>
                    </h2>
                    <div id="collapse-1" class="accordion-collapse collapse" data-bs-parent="#accordion-example" style="">
                      <div class="accordion-body pt-0">
                        <h5>RMT (translated. Real Money Trading) - trading for real money items, goods and services in online games.</h5>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading-2">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false">
                        What falls under RMT
                      </button>
                    </h2>
                    <div id="collapse-2" class="accordion-collapse collapse" data-bs-parent="#accordion-example" style="">
                      <div class="accordion-body pt-0">
                        <h5>- sale of any in-game item (weapons, ammo, equipment, locks, building materials, etc...)</h5>
                        <h5>- sale of transport</h5>
                        <h5>- sale of buildings</h5>
                        <h5>- sale of construction services</h5>
                        <h5>- sale of raid services</h5>
                        <h5>- sale of information about players (availability online, location, location of bases, vehicles, caches)</h5>
                        <h5>- selling bot packs for real money</h5>
                        <h5>- individual discounts for real money players</h5>
                        <h5>- block / unblock players, block chat for real money</h5>
                        <h5>- access to use the bot for real money</h5>
                        <h5>- sale of play money</h5>
                        <h5>- sale of game coins and other currencies related to them (for example, a discord currency that can be exchanged for bot coins)</h5>
                        <h5>- getting privileges (rewards in the form of currency, items, vehicles, buildings) for boosting the discord</h5>
                        <h5>- buy one, get all (Items, coins, etc.)</h5>
                        <h5>- shop discount for VIP</h5>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="heading-3">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false">
                        What is not included in RMT
                      </button>
                    </h2>
                    <div id="collapse-3" class="accordion-collapse collapse" data-bs-parent="#accordion-example" style="">
                      <div class="accordion-body pt-0">
                        <h5>All donations are voluntary and can be returned at the request of the server owner!</h5>
                        <h5>- for donations you do not receive direct gaming benefits</h5>
                        <h5>- for a donation, you can get a slot reserve, this is when you can connect to the server even when it is full, connection (login) priority</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                          <h4>If you notice a player or server that violates the SCUM EULA, you can report it using the form below.</h4>
                          <h4>The information you provide is not public and will be reviewed by the developers of the SCUM game</h4>
                          <div class="mt-3">
                            <a href="#" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal-bad-source" rel="noopener">{{ $t('badSourcePage.createReportButton') }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
  
                <!-- modal send -->
                  <div class="modal modal-blur fade" id="modal-bad-source" tabindex="-1" style="display: none;" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title">SCUM EULA violation report</h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                              <div class="col">
                                  <label class="form-label">Contacts for communication (if you need to contact for clarification)</label>
                                  <input v-model="sendData.name" type="text" class="form-control">
                              </div>
                              <div>
                              <label style="margin-top: 10px;" class="form-label">Your message (Evidence, facts, screenshots, video)</label>
                              <textarea v-model="sendData.description" class="form-control"></textarea>
                              </div>
                              <hr/>
                              <h4>The information will be verified by the developers of the game SCUM!</h4>
                          </div>
                          <div class="modal-footer">
                              <button type="button" class="btn me-auto" data-bs-dismiss="modal">{{ $t('badSourcePage.modalCreateReport.closeButton') }}</button>
                              <button @click="sendReport()" type="button" class="btn btn-primary" data-bs-dismiss="modal">{{ $t('badSourcePage.modalCreateReport.sendReportButton') }}</button>
                          </div>
                          </div>
                      </div>
                  </div>
                <!-- -->
  
                <!-- modal success -->
                  <div class="modal modal-blur fade" v-bind:class="{'show' : successModal.show}" id="modal-success" style="display: block;" v-bind:style="{'display' : successModal.display}" tabindex="-1" aria-hidden="true">
                      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
                          <div class="modal-content">
                          <button type="button" class="btn-close" @click="closeSuccess()" data-bs-dismiss="modal" aria-label="Close"></button>
                          <div class="modal-status bg-success"></div>
                          <div class="modal-body text-center py-4">
                              <!-- Download SVG icon from http://tabler-icons.io/i/circle-check -->
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-green icon-lg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><circle cx="12" cy="12" r="9"></circle><path d="M9 12l2 2l4 -4"></path></svg>
                              <h3>{{ $t('badSourcePage.modalSuccess.header') }}</h3>
                              <div class="text-muted">{{ $t('badSourcePage.modalSuccess.text') }}</div>
                          </div>
                          <div class="modal-footer">
                              <div class="w-100">
                              <div class="row">
                                  <div @click="closeSuccess()" class="col"><a href="#" class="btn w-100" data-bs-dismiss="modal">
                                      {{ $t('badSourcePage.modalSuccess.hideButton') }}
                                  </a></div>
                              </div>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
                <!-- -->
    </div>
  </template>
  
  <script>
  export default {
      name: "EulaViolationPage",
      data: function () {
        return {
          sendData: {
              name: null,
              description: null
          },
          successModal: {
              display: 'none',
              show: false
          }
        }
      },
      methods: {
          sendReport() {
              console.log(this.sendData)
              this.axios.get(document.location.protocol + '//scum-global.com/core/handler.php', {params: {request: "add_eula_violation", info: this.sendData}}).then((response) => {
                if (response.data.success) {
                  this.openSuccess()
                }
              })
          },
          openSuccess() {
              this.successModal.display = 'block'
              this.successModal.show = true
          },
          closeSuccess() {
              this.successModal.display = 'none'
              this.successModal.show = false
          }
      },
      created() {
  
      }
  }
  </script>